/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ticket-file-list {
  margin-top: 8px;
}
.ticket-file-list .ticket-file a {
  text-decoration: underline dashed;
}
.ticket-file-list .processed-status {
  font-size: 1.2em;
  color: #1890ff;
  padding: 0 8px;
  margin-left: 4px;
}
.ticket-file-list .processed-status.done {
  color: #52c41a;
}
.ticket-file-list .processed-status.error {
  color: #f5222d;
}
