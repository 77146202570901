/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ticket-drone {
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ticket-drone::v-deep .ant-tabs-nav {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  user-select: none;
}
.ticket-drone-tabs::v-deep .ant-tabs-bar {
  margin-bottom: 0;
}
.ticket-lot-note {
  margin: 8px 24px;
}
