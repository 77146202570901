/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.note-action-pane {
  text-align: right;
  margin-top: 8px;
}
.note-list::v-deep.mycard-ant-list.ant-list,
.note-list::v-deep.mycard-ant-list.ant-list > .ant-spin-nested-loading {
  min-height: 95px;
}
.note-list::v-deep li.ant-list-item:nth-child(odd) {
  background-color: #fafafa;
}
.note-list::v-deep li.ant-list-item {
  border-bottom: none;
}
.note-pin-form {
  margin-top: 8px;
}
.note-pin-form label {
  color: #1890ff;
}
