/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.action-modal-content::v-deep {
  display: flex;
  align-items: center;
}
.action-modal-content::v-deep .icon > .material-design-icon {
  width: 2.5em;
  height: 2.5em;
  vertical-align: top;
  margin-right: 18px;
}
.action-modal-content::v-deep .icon > .material-design-icon > .material-design-icon__svg {
  width: 2.5em;
  height: 2.5em;
}
.action-modal-content::v-deep .icon > .anticon {
  font-size: 2.5em;
  margin-right: 18px;
}
.action-modal-content::v-deep p {
  margin-bottom: 0;
}
.action-modal-form-item {
  margin-top: 24px;
  margin-bottom: 12px;
}
.action-modal-form-item label {
  display: block;
  color: #1890ff;
}
.action-modal-form-item .ant-select-auto-complete {
  display: block;
  width: 100%;
}
