/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.note-box {
  padding: 12px 16px;
  width: 100%;
  align-self: flex-end;
  font-size: 0.95em;
  position: relative;
}
.note-info {
  color: #8c8c8c;
  padding-top: 8px;
  text-align: right;
  font-size: 0.9em;
}
.note-box-action {
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
}
.note-pin {
  font-size: 16px;
  color: #faad14;
  position: absolute;
  top: 4px;
  left: 8px;
}
.note-content {
  padding: 0;
}
