/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ticket-lite-card {
  width: 100%;
}
.ticket-lite-card .mycard-content {
  position: relative;
  padding-bottom: 2em;
}
.ticket-lite-card .drone-name {
  display: inline-block;
  padding: 2px 6px;
  font-size: 0.85em;
  border: 1px dashed #8fb5ad;
  color: #14837B;
  margin-right: 2px;
  border-radius: 8px;
}
.ticket-lite-card .drone-name-general {
  display: inline-block;
  padding: 2px 6px;
  font-size: 0.85em;
  background-color: #ad8b00;
  color: #fff;
  margin-right: 2px;
  border-radius: 8px;
}
.ticket-lite-card::v-deep .ant-card-body {
  padding-bottom: 8px;
}
.header-row {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.topic-row {
  margin: 12px 0 8px;
  font-size: 0.95em;
}
.tags-row {
  margin-top: 4px;
}
.status-row {
  position: absolute;
  bottom: 0;
  padding-top: 4px;
}
.text-detail {
  color: #8c8c8c;
  font-size: 0.8em;
  vertical-align: middle;
}
.ticket-field-company {
  margin-left: 8px;
  color: #8c8c8c;
  font-size: 0.85em;
}
