/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.comment-box {
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 24px;
  background-color: white;
  max-width: 540px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.comment-box-own {
  border: 1px dashed #1890ff;
  box-shadow: none;
}
.comment-info {
  margin-bottom: 8px;
}
.comment-info-time {
  color: #8c8c8c;
  font-size: 0.85em;
  margin-left: 16px;
}
.comment-content {
  padding: 0 0 12px 24px;
  margin-bottom: -1em;
}
.comment-images {
  margin-top: 8px;
}
