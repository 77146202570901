/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.expandable {
  display: inline-block;
  cursor: pointer;
  user-select: none;
}
div.expandable {
  color: #1890ff !important;
  text-decoration: underline;
  text-decoration-style: dashed;
}
adiv.expandable:hover {
  color: #0076e4 !important;
}
button.expandable {
  text-decoration: none;
}
.expandable .expandable-icon {
  content: '>';
  transition: transform 0.3s;
}
.expandable.expandable-open .expandable-icon {
  transform: rotate(90deg);
}
