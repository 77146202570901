/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mytags-add {
  background: #fff;
  border-style: dashed;
  min-width: 90px;
  text-align: center;
  cursor: pointer;
}
