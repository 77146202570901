/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.image-list {
  display: flex;
  flex-wrap: wrap;
}
.image-list-image {
  padding: 8px;
  margin: 0 4px 8px 0;
  cursor: pointer;
  background: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
}
.image-list-image .image-loader {
  background: transparent;
}
