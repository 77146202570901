/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ticket-container {
  margin-top: 24px;
}
.ticket-actions {
  zoom: 1;
  margin-bottom: 12px;
}
.ticket-actions::before,
.ticket-actions::after {
  display: table;
  content: '';
}
.ticket-actions::after {
  clear: both;
}
.ticket-display-options {
  float: right;
  margin-top: 6px;
}
.drone-detail-btn {
  margin-bottom: 2px;
}
