/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.quill-viewer.ql-editor::v-deep {
  font-size: 13px;
}
.quill-viewer.ql-editor::v-deep .ql-size-small {
  font-size: 0.85em;
}
.quill-viewer.ql-editor::v-deep .ql-size-large {
  font-size: 1.25em;
}
.quill-viewer.ql-editor::v-deep .ql-size-huge {
  font-size: 1.75em;
}
.quill-viewer.ql-editor::v-deep ol,
.quill-viewer.ql-editor::v-deep ul {
  padding-left: 0.5em;
}
