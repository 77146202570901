/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ticket-action-menu .icon {
  width: 1.35em;
  height: 1.35em;
  vertical-align: top;
  margin-right: 6px;
}
.ticket-action-menu .icon > .material-design-icon__svg {
  width: 1.35em;
  height: 1.35em;
}
