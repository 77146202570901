/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.comment-input-more {
  text-decoration: underline !important;
  text-decoration-style: dashed;
  color: #1890ff;
  display: block;
  margin-top: 8px;
}
.comment-input-files {
  margin-top: 12px;
}
.comment-input-data {
  border: 1px dashed #d9d9d9;
  padding: 8px 18px 18px;
  margin-top: 8px;
}
.comment-input-data .title {
  font-size: 1.1em;
  color: #1890ff;
}
