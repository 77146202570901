/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.myupload::v-deep .ant-upload[role='button'] {
  line-height: 30px;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
}
.myupload::v-deep .ant-upload[role='button'] > .anticon {
  line-height: 1;
}
.myupload::v-deep .ant-upload[role='button'],
.myupload::v-deep .ant-upload[role='button']:active,
.myupload::v-deep .ant-upload[role='button']:focus {
  outline: 0;
}
.myupload::v-deep .ant-upload[role='button']:not([disabled]):hover {
  text-decoration: none;
}
.myupload::v-deep .ant-upload[role='button']:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.myupload::v-deep .ant-upload[role='button'].disabled,
.myupload::v-deep .ant-upload[role='button'][disabled] {
  cursor: not-allowed;
}
.myupload::v-deep .ant-upload[role='button'].disabled > *,
.myupload::v-deep .ant-upload[role='button'][disabled] > * {
  pointer-events: none;
}
.myupload::v-deep .ant-upload[role='button']-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.myupload::v-deep .ant-upload[role='button']-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
}
.myupload::v-deep .ant-upload[role='button'] > a:only-child {
  color: currentColor;
}
.myupload::v-deep .ant-upload[role='button'] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.myupload::v-deep .ant-upload[role='button']:hover,
.myupload::v-deep .ant-upload[role='button']:focus {
  color: #2c8f85;
  background-color: #fff;
  border-color: #2c8f85;
}
.myupload::v-deep .ant-upload[role='button']:hover > a:only-child,
.myupload::v-deep .ant-upload[role='button']:focus > a:only-child {
  color: currentColor;
}
.myupload::v-deep .ant-upload[role='button']:hover > a:only-child::after,
.myupload::v-deep .ant-upload[role='button']:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.myupload::v-deep .ant-upload[role='button']:active,
.myupload::v-deep .ant-upload[role='button'].active {
  color: #095c59;
  background-color: #fff;
  border-color: #095c59;
}
.myupload::v-deep .ant-upload[role='button']:active > a:only-child,
.myupload::v-deep .ant-upload[role='button'].active > a:only-child {
  color: currentColor;
}
.myupload::v-deep .ant-upload[role='button']:active > a:only-child::after,
.myupload::v-deep .ant-upload[role='button'].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.myupload::v-deep .ant-upload[role='button']-disabled,
.myupload::v-deep .ant-upload[role='button'].disabled,
.myupload::v-deep .ant-upload[role='button'][disabled],
.myupload::v-deep .ant-upload[role='button']-disabled:hover,
.myupload::v-deep .ant-upload[role='button'].disabled:hover,
.myupload::v-deep .ant-upload[role='button'][disabled]:hover,
.myupload::v-deep .ant-upload[role='button']-disabled:focus,
.myupload::v-deep .ant-upload[role='button'].disabled:focus,
.myupload::v-deep .ant-upload[role='button'][disabled]:focus,
.myupload::v-deep .ant-upload[role='button']-disabled:active,
.myupload::v-deep .ant-upload[role='button'].disabled:active,
.myupload::v-deep .ant-upload[role='button'][disabled]:active,
.myupload::v-deep .ant-upload[role='button']-disabled.active,
.myupload::v-deep .ant-upload[role='button'].disabled.active,
.myupload::v-deep .ant-upload[role='button'][disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.myupload::v-deep .ant-upload[role='button']-disabled > a:only-child,
.myupload::v-deep .ant-upload[role='button'].disabled > a:only-child,
.myupload::v-deep .ant-upload[role='button'][disabled] > a:only-child,
.myupload::v-deep .ant-upload[role='button']-disabled:hover > a:only-child,
.myupload::v-deep .ant-upload[role='button'].disabled:hover > a:only-child,
.myupload::v-deep .ant-upload[role='button'][disabled]:hover > a:only-child,
.myupload::v-deep .ant-upload[role='button']-disabled:focus > a:only-child,
.myupload::v-deep .ant-upload[role='button'].disabled:focus > a:only-child,
.myupload::v-deep .ant-upload[role='button'][disabled]:focus > a:only-child,
.myupload::v-deep .ant-upload[role='button']-disabled:active > a:only-child,
.myupload::v-deep .ant-upload[role='button'].disabled:active > a:only-child,
.myupload::v-deep .ant-upload[role='button'][disabled]:active > a:only-child,
.myupload::v-deep .ant-upload[role='button']-disabled.active > a:only-child,
.myupload::v-deep .ant-upload[role='button'].disabled.active > a:only-child,
.myupload::v-deep .ant-upload[role='button'][disabled].active > a:only-child {
  color: currentColor;
}
.myupload::v-deep .ant-upload[role='button']-disabled > a:only-child::after,
.myupload::v-deep .ant-upload[role='button'].disabled > a:only-child::after,
.myupload::v-deep .ant-upload[role='button'][disabled] > a:only-child::after,
.myupload::v-deep .ant-upload[role='button']-disabled:hover > a:only-child::after,
.myupload::v-deep .ant-upload[role='button'].disabled:hover > a:only-child::after,
.myupload::v-deep .ant-upload[role='button'][disabled]:hover > a:only-child::after,
.myupload::v-deep .ant-upload[role='button']-disabled:focus > a:only-child::after,
.myupload::v-deep .ant-upload[role='button'].disabled:focus > a:only-child::after,
.myupload::v-deep .ant-upload[role='button'][disabled]:focus > a:only-child::after,
.myupload::v-deep .ant-upload[role='button']-disabled:active > a:only-child::after,
.myupload::v-deep .ant-upload[role='button'].disabled:active > a:only-child::after,
.myupload::v-deep .ant-upload[role='button'][disabled]:active > a:only-child::after,
.myupload::v-deep .ant-upload[role='button']-disabled.active > a:only-child::after,
.myupload::v-deep .ant-upload[role='button'].disabled.active > a:only-child::after,
.myupload::v-deep .ant-upload[role='button'][disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.myupload::v-deep .ant-upload[role='button']:hover,
.myupload::v-deep .ant-upload[role='button']:focus,
.myupload::v-deep .ant-upload[role='button']:active,
.myupload::v-deep .ant-upload[role='button'].active {
  text-decoration: none;
  background: #fff;
}
.myupload::v-deep .ant-upload[role='button'] > i {
  margin-right: 4px;
}
