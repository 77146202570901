/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ticket-status {
  font-weight: normal;
  font-family: Helvetica, Arial, sans-serif;
  white-space: nowrap;
}
.ticket-status.ticket-status-tag {
  display: inline-block;
  padding: 0 7px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
}
.ticket-status-tooltip-content {
  padding: 2px 4px;
}
.ticket-status-tooltip-content .reason {
  border-top: 1px dashed #d9d9d9;
  margin-top: 0;
  padding-top: 6px;
  font-size: 12px;
}
.ticket-status-new {
  color: #1890ff;
}
.ticket-status-new.ticket-status-tag {
  color: #fff;
  background: #1890ff;
}
.ticket-status-new.ticket-status-tag:hover {
  opacity: 0.85;
}
.ticket-status-tooltip-new .description {
  color: #1890ff;
}
.ticket-status-bg-new {
  background-color: #1890ff;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.ticket-status-outline-new {
  border: 1px solid #1890ff;
  padding: 3px 5px;
  border-radius: 5px;
  color: #1890ff;
}
.ticket-status-investigate {
  color: #FFC425;
}
.ticket-status-investigate.ticket-status-tag {
  color: #fff;
  background: #FFC425;
}
.ticket-status-investigate.ticket-status-tag:hover {
  opacity: 0.85;
}
.ticket-status-tooltip-investigate .description {
  color: #FFC425;
}
.ticket-status-bg-investigate {
  background-color: #FFC425;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.ticket-status-outline-investigate {
  border: 1px solid #FFC425;
  padding: 3px 5px;
  border-radius: 5px;
  color: #FFC425;
}
.ticket-status-reopen {
  color: #1890ff;
}
.ticket-status-reopen.ticket-status-tag {
  color: #fff;
  background: #1890ff;
}
.ticket-status-reopen.ticket-status-tag:hover {
  opacity: 0.85;
}
.ticket-status-tooltip-reopen .description {
  color: #1890ff;
}
.ticket-status-bg-reopen {
  background-color: #1890ff;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.ticket-status-outline-reopen {
  border: 1px solid #1890ff;
  padding: 3px 5px;
  border-radius: 5px;
  color: #1890ff;
}
.ticket-status-resolved {
  color: #F37735;
}
.ticket-status-resolved.ticket-status-tag {
  color: #fff;
  background: #F37735;
}
.ticket-status-resolved.ticket-status-tag:hover {
  opacity: 0.85;
}
.ticket-status-tooltip-resolved .description {
  color: #F37735;
}
.ticket-status-bg-resolved {
  background-color: #F37735;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.ticket-status-outline-resolved {
  border: 1px solid #F37735;
  padding: 3px 5px;
  border-radius: 5px;
  color: #F37735;
}
.ticket-status-close {
  color: #52c41a;
}
.ticket-status-close.ticket-status-tag {
  color: #fff;
  background: #52c41a;
}
.ticket-status-close.ticket-status-tag:hover {
  opacity: 0.85;
}
.ticket-status-tooltip-close .description {
  color: #52c41a;
}
.ticket-status-bg-close {
  background-color: #52c41a;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.ticket-status-outline-close {
  border: 1px solid #52c41a;
  padding: 3px 5px;
  border-radius: 5px;
  color: #52c41a;
}
.ticket-status-pending {
  color: #FFC425;
}
.ticket-status-pending.ticket-status-tag {
  color: #fff;
  background: #FFC425;
}
.ticket-status-pending.ticket-status-tag:hover {
  opacity: 0.85;
}
.ticket-status-tooltip-pending .description {
  color: #FFC425;
}
.ticket-status-bg-pending {
  background-color: #FFC425;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.ticket-status-outline-pending {
  border: 1px solid #FFC425;
  padding: 3px 5px;
  border-radius: 5px;
  color: #FFC425;
}
.ticket-status-cancel {
  color: #000;
}
.ticket-status-cancel.ticket-status-tag {
  color: #fff;
  background: #000;
}
.ticket-status-cancel.ticket-status-tag:hover {
  opacity: 0.85;
}
.ticket-status-tooltip-cancel .description {
  color: #000;
}
.ticket-status-bg-cancel {
  background-color: #000;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.ticket-status-outline-cancel {
  border: 1px solid #000;
  padding: 3px 5px;
  border-radius: 5px;
  color: #000;
}
