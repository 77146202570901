/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.note-editor.richtext-editor .ql-editor {
  min-height: 250px;
}
.note-clear-btn {
  position: absolute;
  left: 16px;
}
