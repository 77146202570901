/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ticket-detail-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 4px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.visible-tablet {
  display: none !important;
}
@media (min-width: 800px) and (max-width: 1279px) {
  .visible-tablet {
    display: block !important;
  }
}
@media (min-width: 800px) and (max-width: 1279px) {
  .hidden-tablet {
    display: none  !important;
  }
}
.ticket-col {
  flex-basis: 33%;
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ticket-col > label {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #14837B;
  min-width: 135px;
  flex-basis: 135px;
  background-color: #f5f5f5;
  padding: 6px 8px;
}
.ticket-col > .ticket-val {
  flex-grow: 1;
  padding: 6px 8px;
  min-width: 0;
}
.ticket-col > .ticket-val .ticket-val-row {
  margin-bottom: 2px;
}
.ticket-col > .ticket-val .ticket-val-row:last-child {
  margin-bottom: 0;
}
.ticket-col.col-span-2 {
  flex-basis: 66%;
}
.ticket-col.col-span-3 {
  flex-basis: 100%;
}
@media (max-width: 799px) {
  .ticket-col {
    flex-basis: 100%;
  }
  .ticket-col.col-span-2 {
    flex-basis: 100%;
  }
}
@media (min-width: 800px) and (max-width: 1279px) {
  .ticket-col {
    flex-basis: 50%;
  }
  .ticket-col.col-span-2 {
    flex-basis: 50%;
  }
  .ticket-col.col-span-2-tablet {
    flex-basis: 100%;
  }
}
.ticket-val-row.ticket-field-status {
  margin-bottom: 6px !important;
}
.ticket-val-row.ticket-field-status label {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #14837B;
  display: inline-block;
  width: 75px;
}
.ticket-val-row.ticket-field-status label::after {
  content: ' :';
}
.ticket-field-ticket_no {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #14837B;
  font-size: 16px;
}
.ticket-val.ticket-field-topic {
  min-height: 64px;
}
