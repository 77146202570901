/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.comment-box {
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 12px;
  max-width: 750px;
  background-color: #feffe6;
  align-self: flex-end;
  font-size: 0.95em;
}
.comment-info {
  color: #8c8c8c;
  padding-top: 12px;
  text-align: right;
  font-size: 0.9em;
}
.comment-content::v-deep.ql-editor {
  padding: 0;
}
.comment-content::v-deep p {
  padding-bottom: 0.5em;
}
.comment-content::v-deep p.topic {
  font-weight: 600;
}
.comment-content::v-deep ul {
  padding-left: 0;
  font-size: 0.9em;
}
.comment-content::v-deep .tag {
  padding: 0 7px;
  display: inline-block;
  margin: 0 4px;
  background: #1890ff;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  white-spze: nowrap;
}
